const initialProjects = {
  oldProjects: [
    {
      categoryId: '1',
      categoryName: 'Architectural-Systems',
      _id: '1',
      projectName: 'F50 Covered',
      project_img: '/images/category/architect/f3.jpg',
    },
    {
      categoryId: '1',
      categoryName: 'Architectural-Systems',
      _id: '2',
      projectName: 'AFC50 Covered',
      project_img: '/images/category/architect/f1.jpg',
    },
    {
      categoryId: '1',
      categoryName: 'Architectural-Systems',
      _id: '3',
      projectName: 'ASR65 Steel-Reinforced',
      project_img: '/images/category/architect/f2.jpg',
    },
    {
      categoryId: '1',
      categoryName: 'Architectural-Systems',
      _id: '4',
      projectName: 'Sliding System',
      project_img: '/images/category/architect/s1.jpg',
    },
    {
      categoryId: '1',
      categoryName: 'Architectural-Systems',
      _id: '5',
      projectName: 'Sliding System',
      project_img: '/images/category/architect/s2.jpg',
    },
    {
      categoryId: '1',
      categoryName: 'Architectural-Systems',
      _id: '6',
      projectName: 'Sliding System',
      project_img: '/images/category/architect/s3.jpg',
    },
    {
      categoryId: '1',
      categoryName: 'Architectural-Systems',
      _id: '7',
      projectName: 'Sliding System',
      project_img: '/images/category/architect/s4.jpg',
    },
    {
      categoryId: '1',
      categoryName: 'Architectural-Systems',
      _id: '8',
      projectName: 'Sliding System',
      project_img: '/images/category/architect/s5.jpg',
    },
    {
      categoryId: '1',
      categoryName: 'Architectural-Systems',
      _id: '9',
      projectName: 'Sliding System',
      project_img: '/images/category/architect/s6.jpg',
    },
    {
      categoryId: '1',
      categoryName: 'Architectural-Systems',
      _id: '10',
      projectName: 'Sliding System',
      project_img: '/images/category/architect/s7.jpg',
    },
    {
      categoryId: '1',
      categoryName: 'Architectural-Systems',
      _id: '11',
      projectName: 'Sliding System',
      project_img: '/images/category/architect/s8.jpg',
    },
    {
      categoryId: '1',
      categoryName: 'Architectural-Systems',
      _id: '12',
      projectName: 'Sliding System',
      project_img: '/images/category/architect/s9.jpg',
    },
    {
      categoryId: '1',
      categoryName: 'Architectural-Systems',
      _id: '13',
      projectName: 'FW77 Folding System',
      project_img: '/images/category/architect/fold1.jpg',
      description:
        'FW77+ insulated folding system in the product Zahit Aluminium, is an excellent solution for access between indoors and outdoors. Designed with the feature of stacking aside by sliding and folding the sash profiles, these systems are widely used in residences, villas, terraces, and restaurants. FW77+ insulated folding system has the following features:',
    },
    {
      categoryId: '1',
      categoryName: 'Architectural-Systems',
      _id: '14',
      projectName: 'FW60 Folding System',
      project_img: '/images/category/architect/fold2.jpg',

      description:
        'FW60 folding system in the product Zahit Aluminium, are often preferred with the benefits they provide to the areas where they are used. Offering great convenience to users with the comfort of access between indoors and outdoors, these systems have been designed with the feature of sliding & folding the sash profiles and stacking them aside. Used in residences, villas, terraces, and restaurants, FW60 folding system have the following advantages: Sashes can be opened and closed easily thanks to the wheels and rails specially designed for folding window and door systems.',
    },

    // Category 2

    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '15',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s1.jpg',

      description: '',
    },

    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '16',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s2.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '17',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s3.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '18',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s4.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '19',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s5.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '20',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s6.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '21',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s7.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '22',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s8.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '23',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s9.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '24',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s10.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '25',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s11.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '26',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s12.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '27',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s13.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '228',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s14.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '29',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s15.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '30',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s16.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '31',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s17.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '32',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s18.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '33',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s19.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '34',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s20.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '35',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s21.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '36',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s22.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '37',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s23.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '38',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s24.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '39',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s25.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '40',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s26.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '41',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s27.jpg',

      description: '',
    },
    {
      categoryId: '2',
      categoryName: 'Standard Products',
      _id: '42',
      projectName: 'Standard Products',
      project_img: '/images/category/standard/s28.jpg',

      description: '',
    },

    // Category 3
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '43',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a1.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '44',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a2.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '45',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a3.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '46',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a4.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '47',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a5.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '48',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a6.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '49',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a7.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '50',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a8.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '51',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a9.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '52',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a10.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '53',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a11.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '54',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a12.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '55',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a13.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '56',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a14.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '57',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a15.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '58',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a16.jpg',

      description: '',
    },
    {
      categoryId: '3',
      categoryName: 'Accessories',
      _id: '59',
      projectName: 'Accessories',
      project_img: '/images/category/accessories/a17.jpg',

      description: '',
    },

    {
      categoryId: '4',
      categoryName: 'composite-panels',
      _id: '60',
      projectName: 'composite-panels',
      // project_img: '',

      description: '',
    },

    
  ],
};

export default initialProjects;
