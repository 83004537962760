import React from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

export default function Product(props) {
   const { product } = props;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      <Link
        className="text-color"
        to={`/products/${product._id}`}
        onClick={scrollToTop}
      >
        <Card className="card-2 exampleOurWrk reveal">
          <img
            className="medium workImage"
            src={product.project_img}
            alt={product.projectName}
          />

          <Card.Body className="contentOurWork">
            <Card.Text>{product.projectName}</Card.Text>
          </Card.Body>
        </Card>
      </Link>
    </div>
  );
}
