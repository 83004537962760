import React from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
// import Button from "react-bootstrap/Button";

export default function Category(props) {
  
  const { category } = props;
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
    <Card key={category._id} className="box d-flex w-100 col-md-3 col-sm-4">
      <Link
        className="text-color"
        to={`/category/${category._id}`}
        onClick={scrollToTop}
      >
        <img
          className="medium workImage"
          src={category.image}
          alt={category.name}
        />
        <div className="service-name">{category.name}</div>
      </Link>
    </Card>
  </div>
    
  );
}
