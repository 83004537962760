import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextAnimation from '../components/TextAnimation';
import initialProjects from '../initialProjects';
import CAtegoryItems from '../components/CAtegoryItems';

export default function ProductsCategory() {
  const { id } = useParams();
  const [mydata, setMydata] = useState([]);
  const [categoryName, setCategoryName] = useState('');

  useEffect(() => {
    if (initialProjects && initialProjects.oldProjects) {
      const filteredProjects = initialProjects.oldProjects?.filter(
        (x) => x.categoryId === id
      );
      setMydata(filteredProjects);
      setCategoryName(
        filteredProjects.find((x) => x.categoryName).categoryName
      );
    }
  }, [id]);

  if (mydata.length === 0) {
    return <div>Loading...</div>;
  }

  console.log(categoryName);

  const bannerImage = '../images/standard-banner.jpg';

  return (
    <>
      <div className="imgHeaderService ">
        <img
          className="d-flex w-100 bg-image"
          src={bannerImage}
          alt={bannerImage}
        ></img>
        <div className="content">
          <h6>
            <TextAnimation />
          </h6>
          <strong>{categoryName}</strong>
        </div>
      </div>
      <div
        id="our_products"
        className="container-fluid d-flex  container-section-first "
      >
        <div className="container my-4 ">
          <div className="col-service ">
            <h1 className="h1-title">{categoryName} Products</h1>

            <Row>
              {mydata?.map((product, index) => (
                <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-3">
                  <CAtegoryItems product={product} />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
