import React from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com';

const Mailer = () => {
  const { t } = useTranslation();
  function sendEmail(e) {
    alert('You message has been send successfully');
    e.preventDefault();
    emailjs
      .sendForm(
        'service_lvztg42',
        'template_l8g0ln8',
        e.target,
        'Ps0owUJKhtm6NmLsl'
      )
      .then(
        (res) => {
          console.log(res.text);
        },
        (err) => {
          console.log(err.text);
        }
      );
    e.target.reset();
  }
  return (
    <div className="container">
      <form
        className="row formContact"
        // style={{ margin: "25px 85px 75px 100px" }}
        onSubmit={sendEmail}
      >
        <label>{t('form_name')}</label>
        <input
          type="text"
          name="name"
          className="input_text form-control mb-2"
          placeholder="Your name"
        ></input>

        <label>{t('form_phone')}</label>
        <input
          type="phone"
          name="user_phone"
          className="input_text  form-control mb-2"
          placeholder="Your Phone Number"
        ></input>

        <label>{t('form_message')}</label>
        <textarea
          name="message"
          rows="6"
          className="input_text  form-control"
          placeholder="Your message"
        ></textarea>

        <input
          type="submit"
          value="Submit"
          className="form-control send-btn"
          style={{ marginTop: '30px' }}
        ></input>
      </form>
    </div>
  );
};

export default Mailer;
