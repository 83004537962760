import React from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

export default function CAtegoryItems(props) {

  const { product } = props;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      <Link
        className="text-color"
        to={`/products/${product._id}`}
        onClick={scrollToTop}
      >
      <Card className="card-2 exampleOurWrk">
      <img
        className="medium workImage"
        src={product.project_img}
        alt={product.projectName}
      />

      <Card.Body className="contentOurWork">
        {/* <Link to="/projects" className="item">
          <i className="fa fa-expand sizeOfIt"></i>
        </Link> */}
        <Card.Text>{product.projectName}</Card.Text>
        {/* <Link  className="text-link" to="/projects" onClick={scrollToTop}>{t('go_project')}</Link> */}
      </Card.Body>
    </Card>
    </Link>
      {/* <Card key={product._id} className="box d-flex w-100 col-md-3 col-sm-4">
        <Link
          className="text-color"
          to={`/product/${product._id}`}
          onClick={scrollToTop}
        >
          <img
            className="img-section"
            src={product.image}
            alt={product.projectName}
          />
          <div className="service-name">{t(`${product.name}`)}</div>
        </Link>
      </Card> */}
    </div>
  );
}
