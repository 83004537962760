import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

export default function ProjectCard(props) {
  const { t } = useTranslation();
  const { project } = props;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      <Card key={project._id} className="box d-flex w-100 col-md-3 col-sm-4">
        {/* <Link
          className="text-color"
          to={`/project/${project._id}`}
          onClick={scrollToTop}
        > */}
          <img className="img-section" src={project.image} alt={project.name} />
          {/* <div className="service-name">{t(`${project.name}`)}</div> */}
        {/* </Link> */}
      </Card>
    </div>
  );
}
