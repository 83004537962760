const data = {
  brands: [
    {
      _id: '1',
      name: 'Master Italy Accessories Range',
      image: '/images/category/master-italy-accessories-range.jpg',
 
    },
    {
      _id: '2',
      name: 'Stac Accessories Range',
      image: '/images/category/stac-accessories-range.jpg',
     
    },
    {
      _id: '3',
      name: 'Windoform Accessories Range',
      image: '/images/category/windoform-accessories-range.jpg',
    
    },
    {
      _id: '4',
      name: 'Kale Kilit Product Range',
      image: '/images/category/kale-kilit-product-range.jpg',
     
    },
    {
      _id: '5',
      name: 'Wacker Insulation Range',
      image: '/images/category/wacker-insulation-range.jpg',
     
    },
    {
      _id: '6',
      name: 'Yılmaz Machine Accessories Range',
      image: '/images/category/yilmaz-machine-accessories-range.jpg',
     
    },
  ],
};
export default data;
