const data = {
  categories: [
    {
      _id: '1',
      name: 'Architectural Systems',
      image: '/images/category/architectural-systems.jpg',
      imageBranchf: ['Business Website', 'Personal Website', 'Ecommerce Store'],

      description: 'Architectural Systems',
    },
    {
      _id: '2',
      name: 'Standard Products',
      image: '/images/category/standard-products.jpg',
      description: 'Standard Products',
    },

    {
      _id: '3',
      name: 'Accessories Range',
      image: '/images/category/accessories-range.jpg',
      description: 'Accessories Range',
    },
    {
      _id: '4',
      name: 'Composite Panels',
      image: '/images/category/composite-panels.jpg',
      description: 'Composite Panels',
    },
  ],
};
export default data;
