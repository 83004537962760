import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import SingleCard from '../components/SngleCard';
import TextAnimation from '../components/TextAnimation';
import  initialProjects  from '../initialProjects';
import { Col } from 'react-bootstrap';

export default function ProjectScreen() {
  const { t } = useTranslation();
  const [mydata, setdata] = useState(initialProjects);
  const [loading, setLoading] = useState(false);

  const bannerImage = '../images/standard-banner.jpg';

  return (
    <>
      <div className="imgHeaderService ">
        <img
          className="d-flex w-100 bg-image"
          src={bannerImage}
          alt={bannerImage}
        ></img>
        <div className="content">
          <h6>
            <TextAnimation />
          </h6>
          <p className="header-job">{t('product_page')}</p>
        </div>
      </div>
      <div className="container-fluid our-products">
      <div className="container">
        <div className="py-5">
          <Row>
            <h1 className="h1-title">{t('product_page')}</h1>
          </Row>
         
          <Row className="flex flex-wrap justify-content-center bg-color-gray">
            {loading ? (
              <div>{/* <img src={spinner} alt="" /> */}</div>
            ) : (
              mydata.oldProjects.map((project, index) => {
                return <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-3">
                <SingleCard data={project} key={project.projectName} />
              </Col>;
              })
            )}
          </Row>
        </div>
      </div>
      </div>
      
    </>
  );
}
