import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import projectData from '../projectData';
import ProjectCard from './projectCard';

export default function Projects() {

  return (
    <div className=" container-fluid   brands-info ">
      <div className=" container shipping-info ">
      <Row>
        <h3 className="h1-title">Accessories Range</h3>

        {/* <p>{t('our_quot')}</p> */}
      </Row>
      <Row>
        {projectData.brands.map((project) => (
          <Col key={project._id} sm={6} md={4} lg={3} className="mb-3">
            <ProjectCard project={project}></ProjectCard>
          </Col>
        ))}
      </Row>
    </div>
    </div>
  );
}
