import React from 'react';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

// import logo from "../images/logoSm.png";

const SingleCard = ({ data }) => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (

    <div className="projectCard">
      <Link
        className="text-color"
        to={`/products/${data._id}`}
        onClick={scrollToTop}
      >
        <Card className="card-2 exampleOurWrk">
          <img
            className="medium workImage"
            src={data.project_img}
            alt={data.projectName}
          />

          <Card.Body className="contentOurWork">
            <Card.Text>{data.projectName}</Card.Text>
          </Card.Body>
        </Card>
      </Link>
    </div>
    // <Card style={{ width: '18rem' }} className="projectCard">
    //   <Card.Img variant="top" src={data.project_img} />
    //   <Card.Body>
    //     <Card.Title>{data.projectName}</Card.Title>
    //     {/* <span>{data.created_at.split("T")[0]} </span> */}
    //     {/* <Card.Text>{data.description}</Card.Text> */}
    //   </Card.Body>

    //   <Card.Body>
    //     {/* <Card.Link href={data.gitHub_Url}>Github</Card.Link> */}
    //     {/* <Card.Link href={`/product/${data._id}`}>Live</Card.Link> */}
    //   </Card.Body>
    // </Card>
  );
};
export default SingleCard;
